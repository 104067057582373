<template>
  <div class="mt-3 ">
    <div class="rounded shadow p-2 mt-2 hover:bg-gray-200">
      <label class="flex items-center  cursor-pointer">

        <input type="radio" class="inline mr-2"
               @change="$emit('change',  $event )"
               :name="name"
               :value="option.original_serial"
               :checked="option.is_given" >

        <div v-html="parseString(option.title)" class="inline html-text"></div>

      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "exam-question-option-item",
  emits: ['change'],
  props: ['option', 'name'],
  methods: {
    parseString( s ){
      return String(s).replace(/https:/gi,'http:');
    },
  }
}
</script>

<style scoped>

</style>