<template>
   

  <div class="relative mb-3 sticky z-50 top-8 bg-green-100 -mx-4 px-4">

    <div class="flex justify-between max-w-6xl  pt-2  mx-auto items-center">
      <h1 class="text-lg md:text-xl font-semibold">{{exam.name || 'Loading...'}}</h1>
      <div class="text-lg">{{ examTime }}</div>
    </div>

    <div class="flex justify-between max-w-6xl pb-2 pt-1 mx-auto items-center border-t border-gray-400">

      <div class="flex justify-between w-80">
        <div class="text-xs">Total Questions: {{exam.numOfQuestion}}</div>
        <div class="text-xs">Negative Mark: {{exam.perQuesNegativeMark}}</div>
        <div class="text-xs">Full Mark: {{ exam.fullMark }}</div>
      </div>

      <div>
        <btn variant="warning" @click="confirmToFinish" :disabled="examSubmitted || answerSubmitting">Finish Exam</btn>
      </div>

    </div>

  </div>

  <div class="relative w-full max-w-6xl my-3 mx-auto">

    <div class="w-full my-3 relative">
      <overly class="z-50 bg-opacity-100"  background="bg-gray-100" v-if="!loading && questions.length == 0">
        <div class="flex flex-col items-center border-5 border-red-500" style="z-index: 5000000">
          <div>{{submission_message}}</div>
          <div class="flex justify-center mt-4">
            <btn :loading="examSubmitting" @click="finishExam" v-if="!examSubmitted" class="mx-2" :disabled="examSubmitted || answerSubmitting">Submit Exam</btn>
            <router-link v-if="examSubmitted" :to="programId ? `/my/courses/${programId}/exams`:`my/exams`"
                         class="mx-2 px-6 py-1 text-xl bg-blue-600 text-white rounded shadow-sm flex items-center">
              Back To My Exams
            </router-link>
            <router-link class="mx-2 px-6 py-1 text-xl bg-blue-600 text-white rounded shadow-sm flex items-center"
                         :to="'/my/exams/' + examId + '/result/' + participantId " v-if="false">View Result</router-link>
          </div>
        </div>
      </overly>

      <div class="my-3 relative" style="min-height: 400px">

        <overly class="rounded" background="bg-blue-100" v-if="loading">
          <spinner class="text-red-600 h-10 w-10"></spinner>
          Loading...
        </overly>

        <div v-for="(question, index) in questions" :key="question.question_id">

          <div class="border border-gray rounded shadow p-4 mb-4 relative bg-white">
            <overly v-if="this.submittingQuestions.includes(question.exam_question_id)" background="bg-green-300" opacity="bg-opacity-25" class="rounded">
              <div class="text-xl" >Submitting...</div>
            </overly>

            <div>
              <div v-if="answerStatusList[question.exam_question_id]" :class="responseMessage(question.exam_question_id).class">
                {{ responseMessage(question.exam_question_id).text }}
              </div>
              <div class="text-gray-500">
                <span>Question {{ index + 1 }}</span>
              </div>
              <div v-html="parseString(question.question_title)" class="html-text"></div>
            </div>

            <div>
              <exam-question-option-item v-for="opt in  questionOptions(question)"
                                         :option="opt" @change="answerSubmit"
                                         :name="question.exam_question_id" />

            </div>

          </div>

        </div>

        <btn variant="warning" @click="confirmToFinish" >Finish Exam</btn>

      </div>

    </div>

    <overly position="fixed" v-if="ExamSubmitConfirm">
      <div class="static top-0"  style="z-index: 60000">
        <div class="max-w-lg bg-white">
          <div class="block px-6">


            <template  v-if="remainingTime>0">
              <div class="border-b py-2 font-semibold text-lg text-center">
                Exam Submit Instruction
              </div>

              <div class="py-2 overflow-y-auto" style="max-height: 150px;">
                <ul class="list-disc">
                  <li class="mb-2">আপনি কি পরীক্ষাটি শেষ করতে চান? </li>
                  <li class="mb-2">পরীক্ষাটি এই মুহূর্তে শেষ না করে পুনরায় আপনার প্রশ্নোত্তর পরিবর্তন করতে চাইলে "Back to Exam" বাটন এ ক্লিক করুন।</li>
                  <li class="mb-2">পরীক্ষাটি এই মুহূর্তে শেষ করতে চাইলে "Finish Exam Now" বাটন এ ক্লিক করুন। একবার ক্লিক করার পর এই পরীক্ষাটি পুনরায় দেওয়ার সুযোগ থাকবে না।</li>
                  <li class="mb-2">পরীক্ষাটির জন্য নির্ধারিত "সময়কাল" এর পরে পরীক্ষা স্বয়ংক্রিয়ভাবে সাবমিট হয়ে যাবে।</li>
                  <li class="mb-2">Result Publish Time এরপরে আপনার রেজাল্ট এবং উত্তরের বিস্তারিত দেখতে পাবেন।</li>
                </ul>
              </div>
            </template>

            <template v-else>
              <div class="border-b py-2 font-semibold text-lg text-center">
                Exam Submission
              </div>

              <div class="py-2 mt-10 text-center overflow-y-auto" style="max-height: 150px;">
                Exam has not submitted! Submit your exam.
              </div>

            </template>

            <div class="border-b py-8 pb-8 px-1 font-semibold text-xl text-center flex justify-center">
              <btn variant="danger" size="md"  :loading="examSubmitting" @click="finishExam" :disabled="examSubmitted || answerSubmitting">
                <span v-if="remainingTime>0">Finish </span><span v-else>Submit </span>Exam <span class="hidden sm:inline">Now</span>
              </btn>

              <btn @click="ExamSubmitConfirm=null" variant="success" size="md"  class="mx-4" v-if="remainingTime>0">
                Back <span class="hidden sm:inline">to Exam</span>
              </btn>
            </div>

          </div>



        </div>
      </div>
    </overly>
  </div>

</template>

<script>
import Spinner from "../../../components/spinner";
import Overly from "../../../components/overly";
import Btn from "../../../components/btn";
import ExamQuestionOptionItem from "./components/exam-question-option-item";
export default {
  name: "exam-participation",
  components: {ExamQuestionOptionItem, Btn, Overly, Spinner},
  data( ){
    return {
      endTime: '',
      exam: {},
      questions: [],
      submittingQuestions:[],
      answerStatusList: {},
      participantId: 0,
      loading: true,
      examTime: '00:00:00',
      submission_message: '',
      examSubmitting: false,
      examSubmitted: false,
      remainingTime: 0,
      answerQueue:[],
      queueStarted: false,
      answerSubmitting: false,
      programId: null,
      questionInfoVisible: true,
      ExamSubmitConfirm:false,
    }
  },

  methods:{

    confirmToFinish(){
      // console.log('Check');
      this.ExamSubmitConfirm = true;
    },
    parseString( s ){
      return String(s).replace(/https:/gi,'http:');
    },
    endExamTime( ){
      this.ExamSubmitConfirm = false;
      this.remainingTime = 0;
      this.questions = [];
      this.examTime = "EXPIRED";
    },
    finishExam( ){
      this.ExamSubmitConfirm = false
      this.submission_message = "Submitting your exam...";
      this.examSubmitting = true;

      this.$store.dispatch( 'exam/finish', {
        data: {
          participant_exam_id: this.participantExamId
        },
        success : ({ data }) => {
          this.examSubmitted = true;
          this.endExamTime();
          this.submission_message = "Thank you for submitting exam";
        },

      }).finally( () => {
        this.examSubmitting = false
      });

    },
    questionOptions( question ){
      return Array.isArray( question.question_options ) ? question.question_options: [];
    },
    answerSubmit( e ) {


      this.submittingQuestions.push(parseInt(e.target.name));

      const data = {
        participant_exam_id   : parseInt(this.participantExamId),
        exam_question_id           : parseInt(e.target.name),
        answer                : e.target.value,
        event                 : e,
      }

      const filtered = this.answerQueue.filter( item => item.participant_exam_id === data.participant_exam_id && item.exam_question_id === data.exam_question_id  );

      if( filtered.length > 0 && filtered[0] ) {
        //const answer = filtered[0];
        filtered[0].answer = data.answer;
        filtered[0].event = e;

      } else {
        this.answerQueue.push( data );
      }


      if( !this.queueStarted ) {
        this.submitAndDequeueAnswers();
      }

    },
    submitAndDequeueAnswers( ){

      if( this.answerQueue.length > 0 ) {
        const input = this.answerQueue[0];
        this.answerSubmitting = true;

        this.$store.dispatch( 'exam/answer', {
          data: input,
          success: (res) => {
            const data = res.data;
            this.answerStatusList[ input.exam_question_id ] = {
              text: "Submitted",
              statusCode: res.status
            };
          },
          error: (res) => {
            //console.log( 'EVENTLLLLLL',input.e );
            input.event.target.checked = false;
            this.answerStatusList[ input.exam_question_id ] = {
              text: "Failed to submit, try again.",
              statusCode: typeof res == 'undefined' ? 0: res.status
            };
          }
        }).finally( () => {
          this.hideOverly( input.exam_question_id );
          this.answerSubmitting = false;
          this.answerQueue.shift();
          setTimeout( this.submitAndDequeueAnswers, 1 );
        });
        this.queueStarted = true;
      } else {
        this.queueStarted = false;
      }
    },
    hideOverly(questionId) {
      const index = this.submittingQuestions.indexOf(parseInt(questionId));
      if (index > -1) {
        this.submittingQuestions.splice(index, 1);
      }
    },
    responseMessage(questionId) {
      const messageData = this.answerStatusList[questionId];

      if(messageData) {
        let className = '';

        if( messageData.statusCode >= 200 && messageData.statusCode <=206 ) {
          className = 'text-green-400';
        } else {
          className = 'text-red-400';
        }

        return {
          text: messageData.text,
          class: className,
        };
      }
      return {
        text: "",
        class: "",
      };
    },
    startCountdown(){
      // Update the count down every 1 second

      let x = setInterval(() => {
        // Time calculations for  hours, minutes and seconds
        let hours = Math.floor(( this.remainingTime ) / ( 60 * 60 ));
        let minutes = Math.floor((this.remainingTime % ( 60 * 60 )) / ( 60 ));
        let seconds = Math.floor((this.remainingTime % (60)) );

        if( hours <= 9 ) {
          hours = '0'+hours
        }

        if( minutes <= 9 ) {
          minutes = '0'+minutes
        }

        if( seconds <= 9 ) {
          seconds = '0' + seconds
        }

        // Output the result in an element with id="demo"
        this.examTime = hours + ":" + minutes + ":" + seconds;

        // Reducing the exam time
        this.remainingTime--;

        // If the count down is over, write some text
        if (this.remainingTime  < 0) {

          clearInterval( x );
          this.submission_message = this.submission_message !== "" ? "Exam time ended":this.submission_message;
          this.endExamTime();
        }

      }, 1000);

    },
    setExamData( data, status ){

      console.log( data );

      this.exam = data.exam || { };
      this.exam = data.exam || { };
      this.questions = data.examQuestions || [ ];
      this.endTime = data.endTime;
      this.remainingTime = data.remainingTime;
      this.participantId = data.participantId;
      this.programId = data.programId;

      if( status == 403 ) {
        this.examTime = "EXPIRED";
        this.examSubmitted = data.examSubmitted;
        // this.submission_message = "Exam time ended";
        this.submission_message = this.submission_message !== "" ? "Exam time ended":this.submission_message;
      }

    },
    headerChange(top){
      if( top <= 100 && !this.questionInfoVisible){
        this.questionInfoVisible = true;
      } else if (this.questionInfoVisible)  {
        this.questionInfoVisible = false;
      }
    }

  },
  computed: {
    participantExamId () {
      return this.$route.params.participantExamId;
    },
    examId () {
      return this.$route.params.examId;
    },


  },
  mounted() {

  },
  created() {

    const data = {
      exam_id             : this.examId,
      participant_exam_id : this.participantExamId
    };

    this.$store.dispatch( 'exam/questions', {
      params: data,
      success: ({data}) => {
        this.setExamData( data, 200 );
        this.startCountdown( );
      },
      error: ( res ) => {
        this.setExamData( res.data || {}, res.status );
        if( res.status == 403 ) {
          this.endExamTime( );
        } else {
          this.$router.push( { path:'/my/courses' });
        }

      }
    }).finally( r => this.loading = false );

    // window.onbeforeunload = function (){
    //   return "Are you sure?";
    // }

  },
  beforeUnmount() {

      this.$store.commit('setHeaderData', {
        is_hidden: false
      });

  },
  watch: {
    '$store.state.scrollTop'( top ){
      setTimeout(this.headerChange,400, top);
      //this.headerChange( top );
    }
  },
  beforeRouteLeave(to, from, next) {
    if( this.examSubmitted) {
      next();
    }
    this.ExamSubmitConfirm = true;
  },

}
</script>

<style scoped>
  .instruction-list  {
    text-align: justify;
  }

  .instruction-list ol {
     @apply mb-3
  }
</style>
